import React from 'react';
import { MapContainer, TileLayer,Marker,Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';


const MapComponent = () => {
  const customIcon = new L.Icon({
    iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-red.png',
    iconSize: [38, 95],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
  });
  const center = [36.38473161344598, 43.180559101048196]; // Default map center (latitude, longitude)
  const locations = [
    { name: 'عوينات بغداد', position: [36.38503391729124, 43.18026942247493] },
  ];
  return (
    <MapContainer center={center} zoom={20} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {locations.map((loc, idx) => (
        <Marker key={idx} position={loc.position} icon={customIcon }>
          <Popup>{loc.name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;
