import './App.css';
import { Fragment } from 'react';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
const App = ()=>  {
  return (
      <div>
        <LandingPage />
      </div>
  );
}

export default App;
